@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primary: #016fd0;
  --primary2: #016fd0b5;
  --secondary: #f9fafc;
  --gray: #f6f6f6;
  --main: #1a1918;
  --family-manrope: "Manrope", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: var(--family-manrope) !important;
  color: var(--main);
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: var(--family-manrope);
  background: #ffffff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: initial;
}

textarea,
button,
input {
  outline: none;
}

button:disabled {
  opacity: 0.5;
}

main {
  min-height: 70vh;
}

.layout,
.layout > * {
  font-family: "Inter", sans-serif !important;
}

.sidebar {
  background: #e1e1e166;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.feature-scroll {
  animation: feature-scroll 18s linear infinite;
}

@keyframes feature-scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-194px * 6));
  }
}

@media screen and (max-width: 768px) {
  @keyframes feature-scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-134px * 6));
    }
  }
}

.accordion__button,
.accordion__panel {
  background: #ffffff !important;
  padding: 14px !important;
}

.parent-link .child-link {
  display: none;
}

.parent-link:hover .child-link {
  display: block;
}

.parent-link .accordion {
  border: 0 !important;
}

.parent-link .accordion__button {
  background: transparent !important;
  padding: 0 !important;
}

.parent-link .accordion__panel {
  background: transparent !important;
  padding: 3px 0 3px 2rem !important;
}

.parent-link .accordion__panel a {
  color: var(--main);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
